import React, { useState } from "react";
import { useEffect } from "react";
import { colors, Container, Dialog, DialogContent, Grid } from "@material-ui/core";
import toast from "react-hot-toast";
import { BeatLoader, MoonLoader } from "react-spinners";
import { useStylesDTa } from "../../../../St/comps/UseStyle";
import axios from "axios";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { uri } from "../../../../help/Api";


export function ShowVoucherDialog(isOper){
  
}
export function is_save_sanad(isOper){
  
}
export default function ExtVoucherDialog(props){

  const {voucherDialog, setVoucherDialog} = props;

  const [submitting, setSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const clasTa = useStylesDTa();


  async function createFominv(){

    setVoucherDialog({...voucherDialog, isOpen:false})
    const URL =uri+'SandSQKh_R_RS_'
    setSubmitting(true);
    const data = voucherDialog.data;
   
    let nData = {
      omla: data?.omlaId,
      omlaval: data?.exchange,
      omla1: data?.omlaId,
      omlaval1: data?.exchange,
      cust_id: data?.cust_id,
      sales_emp: data?.sales_emp,
      center: data.center,
      safe_bank_id: data?.stock,
      check_state:  "",
      tot_val: data.valueOfVoucher,
      tot_val1: data?.valueOfVoucher,
      date: data?.date,
      // cust_id:locSto.CustomersA != undefined ? locSto.CustomersA[0].id: -1,
      val: data?.valueOfVoucher,
      val1: data?.valueOfVoucher,
      type: "1",
      emp: data?.emp,
      check_no: "",
      check_date: "",
      checkbank: "",
      check_state: null,
      notes: "سند قبض بالمبلغ المتبقي من فاتورة رقم: "+ data?.id +" الخاصة بالعميل "+data?.customer_name,
      rest_id: "",
      branch: data.branch,
      ref_no: data?.ref_no,
      end_Dept: "",
    };
    const sData = {
      sandQ: nData,
      stor: { invsType: 0 },
    };
    await axios.post(URL, sData).then((res)=>{ 
      setIsSuccess(true);
      toast.success("ext voucher is add");
      voucherDialog.onSuccess();
    }).catch((error) => {toast.error("didn't add voucher");
      setIsSuccess(false);
    });
    setSubmitting(false);
    
}


    if(voucherDialog.isOpen)
      return (
        <ConfirmDialog
          confirmDialog={{...voucherDialog,
            title: "يجب أن يكون المبلغ المدفوع أقل من أو يساوي إجمالي الفاتورة"+" . "+`هل تريد إضافة المبلغ الفارق (${voucherDialog.data?.valueOfVoucher}) كسند قبض باسم العميل؟`,
            subTitle: "ملاحضة: في حالة الرفض سينم إعتماد إجمالي الفاتورة بشكل تلقائي!",
            onConfirm: createFominv,
           }}
          setConfirmDialog={setVoucherDialog}
       
      />
    )
    return (
      <Dialog  
        style={{padding:20}} 
        className={clasTa} open={submitting} onClose={()=>{}}>
        <DialogContent >
        <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <MoonLoader loading={submitting} color={colors.green[300]} />
              
            </Grid>
            <Grid item sx={12} justifyContent="center">
            {"submitting voucher..."}
            </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
      // <div></div>
        
    )

}

   