import { getLS } from "../utils/useLS";
import { insh, premiScr as pScr1, sto } from "./InshData";
import { insh as inshst } from "./InshSt";
import { elmnts, elmntall } from "./Elmnts";
import { col } from "./inshMt";
import { txtA, txtE } from "../Label/Lbl";
import dataF from "../Server/dataF.json";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ReceiptTwoToneIcon from "@material-ui/icons/ReceiptTwoTone";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import SyncAltRoundedIcon from "@material-ui/icons/SyncAltRounded";
import ListAltIcon from "@material-ui/icons/ListAlt";

let pScr = getLS("premiScr", {});
// console.log('pScr')
// console.log(pScr)
// console.log('pScr')

const of = {
  sal: 0,
  parush: 1,
  safe: 2,
  customer: 3,
  account: 4,
  basic: 5,
  users: 6,
  setting: 7,
};

const elIcon = [
  { icon: AddShoppingCartIcon },
  { icon: ShoppingCartIcon },
  { icon: AssignmentReturnIcon },
  { icon: MenuOpenIcon },
  { icon: ReceiptIcon },
  { icon: ReceiptTwoToneIcon },
  { icon: ReceiptOutlinedIcon },
  { icon: SyncAltRoundedIcon },
  { icon: ListAltIcon },
];

const autos = {
  currType: [
    { id: 1, name: "مخزني" },
    { id: 2, name: "خام" },
    { id: 3, name: "صناعي" },
    { id: 4, name: "خدمي" },
    { id: 5, name: "لايباع" },
  ],
};

const itmes = {
  idata: insh.items,
  front: {
    title: txtA.bascData.items,
    col: col.items,
    show: { rightDown: false },
  },
  setting: {
    tb: {
      wait: true,
      other: true,
      groups: true,
      Branches: true,
      tax_groups: true,
      Units: true,
      Currencies: true,
      parms: {
        fltrBranch: true,
        scurrenciestype: "C",
        tname: "curr",
        iempid: sto.empi,
        takei: 20,
      },
      autos: { Branches: true, Units: true, tax_groups: true },
    },
    screen: "Items",
  },
  style: inshst.bascData.all,
};

const inshs = {
  Sales: {
    sal: {
      idata: insh.IdataInvs,
      stor: { invsType: 0 },
      setting: {
        titleHed: txtA.invName.sale,
        titleHedEn: txtE.invName.sale,
        cusType: 1,
        cusN: txtA.cusNA,
        stors: itmes,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSa.ProcType_InvProc,
        iformid: dataF[47].id,
        routI_R_RS: "Inv_R_RS_ToS",
        routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
      },
      style: inshst.sal,
    },
    salRet: {
      idata: insh.IdataInvs,
      stor: { invsType: 1 },
      setting: {
        titleHed: txtA.invName.saleRet,
        titleHedEn: txtE.invName.saleRet,
        cusType: 1,
        cusN: txtA.cusNA,
        stors: itmes,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSaR.ProcType_InvProc,
        iformid: dataF[49].id,
        routI_R_RS: "Inv_R_RS_ToS",
        routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
      },
      style: inshst.ret,
    },
    salViw: {
      idata: insh.IdataInvs,
      stor: { invsType: 2 },
      setting: {
        titleHed: txtA.invName.saleViw,
        titleHedEn: txtE.invName.saleViw,
        cusType: 1,
        cusN: txtA.cusNA,
        stors: itmes,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSaV.ProcType_InvProc,
        iformid: dataF[59].id,
        routI_R_RS: "Inv_R_RS_ToS",
        routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
      },
      style: inshst.sal,
    },
    saleVat: {
      titleHed: txtA.saleVat,
      systemId: 1,
    },
    purchVat: {
      titleHed: txtA.purchVat,
      systemId: 0,
    },
  },
  Purches: {
    purch: {
      idata: insh.IdataInvs,
      stor: { invsType: 3 },
      setting: {
        titleHed: txtA.invName.purch,
        titleHedEn: txtE.invName.purch,
        cusType: 2,
        cusN: txtA.cusNM,
        stors: itmes,
        priceNM: txtA.namePricePurch,
        priceTotNM: txtA.nameTotPurch,
        PrTy_InPr: insh.IdataPa.ProcType_InvProc,
        iformid: dataF[46].id,
        routI_R_RS: "Inv_R_RS_ToP",
        routIS: "Inv_Sub_ToP/" /*routR:'',routRS:'',*/,
      },
      style: inshst.purch,
    },
    purchRet: {
      idata: insh.IdataInvs,
      stor: { invsType: 4 },
      setting: {
        titleHed: txtA.invName.purchRet,
        titleHedEn: txtE.invName.purchRet,
        cusType: 2,
        cusN: txtA.cusNM,
        stors: itmes,
        priceNM: txtA.namePricePurch,
        priceTotNM: txtA.nameTotPurch,
        PrTy_InPr: insh.IdataPaR.ProcType_InvProc,
        iformid: dataF[48].id,
        routI_R_RS: "Inv_R_RS_ToP",
        routIS: "Inv_Sub_ToP/" /*routR:'',routRS:'',*/,
      },
      style: inshst.ret,
    },
  },
  Safes: {
    safe: {
      idata: insh.IdataInvs,
      stor: { invsType: 5 },
      setting: {
        titleHed: txtA.invName.safe,
        titleHedEn: txtE.invName.safe,
        cusType: 2,
        cusN: txtA.cusNM,
        stors: "",
        priceNM: txtA.namePricePurch,
        priceTotNM: txtA.nameTotPurch,
        PrTy_InPr: insh.IdataSf.ProcType_InvProc,
        iformid: dataF[50].id,
        routI_R_RS: "Inv_R_RS_ToSf",
        routIS: "Inv_Sub_ToSf/" /*routR:'',routRS:'',*/,
      },
      style: inshst.safe,
    },
  },
  Customers: [
    { setting: { type: 1 } },
    { setting: { type: 2 } },

    { pcode: 123, front: "cust-account" },
    { pcode: 261, front: "sup-account" },
    { pcode: 123, front: "customers" },
    { pcode: 261, front: "suppliers" },

    // {
    //     idata:insh.Units,
    //     front:
    //     {
    //         elmnts:elmntall[3].card[5].grid[4],
    //         col:col.Units,
    //         title:txtA.customers.arsedah,
    //         show:{rightDown:false,}
    //     },
    //     setting:
    //     {
    //         tb:
    //         {
    //             wait:true, RptAccountBalance:true, Branches:true, costcenters:true, AccountsIndex:true,
    //             parms:insh.RptCustomer.arsedah,
    //             autos:{},
    //         },
    //         screen:'Arsedah',
    //     },
    //     style:inshst.bascData.all
    // }
  ],
  Accounts: {
    sQKharji: {
      sdata: insh.sandQK,
      stor: { invsType: 0 },
      setting: {
        titleHed: txtA.Accounts.sandQK,
        cusType: 1,
        cusN: txtA.cusSQK,
        accN: txtA.accSQK,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSa.ProcType_InvProc,
        iformid: dataF[47].id,
        routI_R_RS: "SandSQKh_R_RS_",
        routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
      },
      style: inshst.account,
    },
    sSKharji: {
      sdata: insh.sandDK,
      stor: { invsType: 1 },
      setting: {
        titleHed: txtA.Accounts.sandSK,
        cusType: 1,
        cusN: txtA.cusSSK,
        accN: txtA.accSSK,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSa.ProcType_InvProc,
        iformid: dataF[47].id,
        routI_R_RS: "SandSSKh_R_RS_",
        routIS: "Inv_Sub_ToS/" /*routR:'',routRS:'',*/,
      },
      style: inshst.account,
    },
    internalSQ: {
      sdata: insh.sandQD,
      stor: { invsType: 0 },
      setting: {
        titleHed: txtA.Accounts.sandQD,
        cusType: 1,
        cusN: txtA.cusSQD,
        accN: txtA.accSQK,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSa.ProcType_InvProc,
        iformid: dataF[47].id,
        routI_R_RS: "SandQD",
        routIS: "/" /*routR:'',routRS:'',*/,
      },
      style: inshst.account,
    },
    internalSS: {
      sdata: insh.sandSD,
      stor: { invsType: 1 },
      setting: {
        titleHed: txtA.Accounts.sandSD,
        cusType: 1,
        cusN: txtA.cusSSD,
        accN: txtA.accSSK,
        priceNM: txtA.namePriceSal,
        priceTotNM: txtA.nameTotSal,
        PrTy_InPr: insh.IdataSa.ProcType_InvProc,
        iformid: dataF[47].id,
        routI_R_RS: "SandSD",
        routIS: "/" /*routR:'',routRS:'',*/,
      },
      style: inshst.account,
    },
  },
  bascData: {
    Foundation: {
      idata: insh.Foundation,
      front: {
        elmnts: elmnts.unit,
        col: col.units,
        title: txtA.bascData.unit,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, Units: true, parms: { tree: "sub" }, autos: {} },
        screen: "Units",
      },
      style: inshst.bascData.all,
    },
    Branches: {
      idata: insh.Branches,
      front: {
        elmnts: elmnts.Branches,
        col: col.Branches,
        title: txtA.bascData.Branches,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, Branches: true, parms: { tree: "sub" }, autos: {} },
        screen: "Branches",
      },
      style: inshst.bascData.all,
    },
    Units: {
      idata: insh.Units,
      front: {
        elmnts: elmnts.Units,
        col: col.Units,
        title: txtA.bascData.unit,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, Units: true, parms: { tree: "sub" }, autos: {} },
        screen: "Units",
      },
      style: inshst.bascData.all,
    },
    groups: {
      idata: insh.groups,
      front: {
        elmnts: elmnts.groups,
        col: col.groups,
        title: txtA.bascData.groups,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, groups: true, parms: { tree: "sub" }, autos: {} },
        screen: "groups",
      },
      style: inshst.bascData.all,
    },
    tax_groups: {
      idata: insh.tax_groups,
      front: {
        elmnts: elmnts.tax_groups,
        col: col.tax_groups,
        title: txtA.bascData.tax_groups,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, tax_groups: true, parms: { tree: "sub" }, autos: {} },
        screen: "tax_groups",
      },
      style: inshst.bascData.all,
    },
    Safes: {
      idata: insh.Safes,
      iemp: insh.Safe_Emps,
      front: {
        elmnts: elmnts.Safes,
        col: col.Safes,
        title: txtA.bascData.Safes,
        titleD: "المخزن",
        show: { rightDown: true },
      },
      setting: {
        tb: {
          wait: true,
          Safes: true,
          Employees: true,
          States: true,
          Branches: true,
          parms: { tree: "sub" },
          autos: { Employees: true, Branches: true },
        },
        screen: "Safes",
        moretb: "Safe_",
      },
      style: inshst.bascData.all,
    },
    Stocks: {
      idata: insh.Stocks,
      iemp: insh.Stock_Emps,
      front: {
        elmnts: elmnts.Stocks,
        col: col.Stocks,
        title: txtA.bascData.Stocks,
        titleD: "الصندوق",
        show: { rightDown: true },
      },
      setting: {
        tb: {
          wait: true,
          Stocks: true,
          Employees: true,
          States: true,
          Branches: true,
          parms: { tree: "sub" },
          autos: { Employees: true, Branches: true },
        },
        screen: "Stocks",
        moretb: "Stock_",
      },
      style: inshst.bascData.all,
    },
    Banks: {
      idata: insh.Banks,
      front: {
        elmnts: elmnts.Banks,
        col: col.Banks,
        title: txtA.bascData.Banks,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          More: true,
          Banks: true,
          AccountType: true,
          Countries: true,
          Cities: true,
          Areas: true,
          parms: { tname: "Acc", tree: "sub" },
          autos: { Countries: true, Cities: true, Areas: true },
        },
        screen: "Banks",
      },
      style: inshst.bascData.all,
    },
    costcenter: {
      idata: insh.costcenter,
      front: {
        elmnts: elmnts.costcenter,
        col: col.costcenter,
        title: txtA.bascData.costcenter,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          costcenters: true,
          parms: { tree: "sub" },
          autos: {},
        },
        screen: "costcenters",
      },
      style: inshst.bascData.all,
    },
    omlat: {
      idata: insh.omlat,
      front: {
        elmnts: elmnts.omlat,
        col: col.omlat,
        title: txtA.bascData.omlat,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          omlat: true,
          Countries: true,
          stateOmlat: true,
          equalOmlat: true,
          parms: { tree: "sub" },
          autos: { Countries: true },
        },
        screen: "omlat",
      },
      style: inshst.bascData.all,
    },
    AccountBooks: {
      idata: insh.AccountBooks,
      front: {
        elmnts: elmnts.AccountBooks,
        col: col.AccountBooks,
        title: txtA.bascData.AccountBooks,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          AccountBooks: true,
          parms: { tree: "sub" },
          autos: {},
        },
        screen: "AccountBooks",
      },
      style: inshst.bascData.all,
    },
    Acts: {
      idata: insh.Acts,
      front: {
        elmnts: elmnts.Acts,
        col: col.Acts,
        title: txtA.bascData.Acts,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          Acts: true,
          parms: { tree: "sub" },
          autos: { Acts: true },
        },
        screen: "Acts",
      },
      style: inshst.bascData.all,
    },
    Countries: {
      idata: insh.Countries,
      front: {
        elmnts: elmnts.Countries,
        col: col.Countries,
        title: txtA.bascData.Countries,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, Countries: true, parms: { tree: "sub" }, autos: {} },
        screen: "Countries",
      },
      style: inshst.bascData.all,
    },
    Cities: {
      idata: insh.Cities,
      front: {
        elmnts: elmnts.Cities,
        col: col.Cities,
        title: txtA.bascData.Cities,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          Cities: true,
          Countries: true,
          parms: { tree: "sub" },
          autos: { Countries: true },
        },
        screen: "Cities",
      },
      style: inshst.bascData.all,
    },
    Areas: {
      idata: insh.Areas,
      front: {
        elmnts: elmnts.Areas,
        col: col.Areas,
        title: txtA.bascData.Areas,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          Areas: true,
          Cities: true,
          parms: { tree: "sub" },
          autos: { Cities: true },
        },
        screen: "Areas",
      },
      style: inshst.bascData.all,
    },
    Managements: {
      idata: insh.Managements,
      front: {
        elmnts: elmnts.Managements,
        col: col.Managements,
        title: txtA.bascData.Managements,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          Managements: true,
          parms: { tree: "sub" },
          autos: {},
        },
        screen: "Managements",
      },
      style: inshst.bascData.all,
    },
    Departments: {
      idata: insh.Departments,
      front: {
        elmnts: elmnts.Departments,
        col: col.Departments,
        title: txtA.bascData.Departments,
        show: { rightDown: false },
      },
      setting: {
        tb: {
          wait: true,
          Departments: true,
          Managements: true,
          parms: { tree: "sub" },
          autos: { Managements: true },
        },
        screen: "Departments",
      },
      style: inshst.bascData.all,
    },
    jobs: {
      idata: insh.jobs,
      front: {
        elmnts: elmnts.jobs,
        col: col.jobs,
        title: txtA.bascData.jobs,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, jobs: true, parms: { tree: "sub" }, autos: {} },
        screen: "jobs",
      },
      style: inshst.bascData.all,
    },
    CardTypes: {
      idata: insh.CardTypes,
      front: {
        elmnts: elmnts.CardTypes,
        col: col.CardTypes,
        title: txtA.bascData.CardTypes,
        show: { rightDown: false },
      },
      setting: {
        tb: { wait: true, CardTypes: true, parms: { tree: "sub" }, autos: {} },
        screen: "CardTypes",
      },
      style: inshst.bascData.all,
    },
  },
  Users: {},
  Settings: {},
};

const local = [
  {
    title: "sales-title",
    leftitle: "sale-system",
    of: of.sal,
    tabs: [
      { name: "page ", nmbr: 1, icon: "", disb: false },
      { name: "page ", nmbr: 2, icon: "", disb: false },
      { name: "page ", nmbr: 3, icon: "", disb: false },
      // {name:'page ',nmbr:4, icon:'',disb:false},
      // {name:'إعدادات المبيعات ',nmbr:'', icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          {
            name: "sale-invoice",
            nmbr: "(1)",
            path: "Invoces",
            icon: "",
            disb: pScr.sal,
            insh: inshs.Sales.sal,
          },
          {
            name: "sale-billet",
            nmbr: "(2)",
            path: "Invoces",
            icon: "",
            disb: pScr.salRet,
            insh: inshs.Sales.salRet,
          },
          {
            name: "invoice-price",
            nmbr: "(3)",
            path: "Invoces",
            icon: "",
            disb: pScr.salView,
            insh: inshs.Sales.salViw,
          },
          // {name:'فاتورة تبديل', nmbr:'(4)',  path:'', icon:'',disb:false},
          {
            name: "active-rpt",
            nmbr: "(4)",
            path: "Activity",
            icon: "",
            disb: pScr.activ,
          },
          {
            name: "daily-rpt",
            nmbr: "(5)",
            path: "ReporteveryDay",
            icon: "",
            disb: pScr.evrDay,
          },
          {
            name: "closing-invoice",
            nmbr: "(6)",
            path: "ReportEqfalEveryDay",
            icon: "",
            disb: pScr.eqfDay,
          },
        ],
      },
      {
        tab: [
          // {name:'تقرير إقفال الوردية', nmbr:'(7)',  path:'', icon:'',disb:false},
          // {name:'تقرير النشاط التجاري', nmbr:'(8)',  path:'Activity', icon:'',disb:pScr.activ},
          {
            name: "sale-tax-rpt",
            nmbr: "(7)",
            path: "sale-report-tax",
            icon: "",
            disb: pScr.tafseel,
            insh: inshs.Sales.saleVat,
          },
          {
            name: "sale-detail-rpt",
            nmbr: "(8)",
            path: "ReportSalTafsele",
            icon: "",
            disb: pScr.tafseel,
          },
          {
            name: "dashboard",
            nmbr: "(9)",
            path: "chart",
            icon: "",
            disb: pScr.chart,
          },
          {
            name: "sale-analysis-rpt",
            nmbr: "(10)",
            path: "ReportSaleAnalysis",
            icon: "",
            disb: pScr.saleAnalysis,
          },
          {
            name: "sale-employee",
            nmbr: "(11)",
            path: "ReportEmpSales",
            icon: "",
            disb: pScr.empSale,
          },
          {
            name: "sale-profit",
            nmbr: "(12)",
            path: "CurrencySalesProfit",
            icon: "",
            disb: pScr.saleProfit,
          },
          // {name:'الحركة اليومية حسب المجموعة', nmbr:'(11)',  path:'', icon:'',disb:false},
          // {name:'هامش الربح تفصيلي', nmbr:'(12)',  path:'', icon:'',disb:false},
        ],
      },
      {
        tab: [
          {
            name: "profit-margin",
            nmbr: "(12)",
            path: "ReportProfitMargin",
            icon: "",
            disb: pScr.profitMargin,
          },
          // {name:'صافي مبيعات وارباح الاصناف', nmbr:'(13)',  path:'', icon:'',disb:false},
          // {name:'مبيعات موظف', nmbr:'(14)',  path:'ReportEmpSales', icon:'',disb:true},
          // {name:'تقرير تحليل المبيعات', nmbr:'(16)',  path:'', icon:'',disb:false},
          // {name:'نقطة البيع', nmbr:'(17)',  path:'', icon:'',disb:false},
          {
            name: "فاتورة المطعم",
            nmbr: "",
            path: "rest-invoice",
            icon: "",
            disb: pScr.sal,
            insh: inshs.Sales.sal,
          },
          {
            name: "مبيعات مندوب خلال فترة",
            nmbr: "(15)",
            path: "salesPersonComm",
            icon: "",
            disb: true,
          },

          // {name:'تقرير مخصص حسب التاريخ', nmbr:'(18)',  path:'', icon:'',disb:false},
        ],
      },
      {
        tab: [
          {
            name: "حركة الصندوق",
            nmbr: "(19)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "بيانات العقود",
            nmbr: "(20)",
            path: "",
            icon: "",
            disb: false,
          },
        ],
      },
      {
        tab: [
          {
            name: "اعدادات المبيعات",
            nmbr: "(21)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "اعدادات نقاط البيع",
            nmbr: "(22)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "نظام الورديات",
            nmbr: "(23)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "اعدادات اخرى",
            nmbr: "(24)",
            path: "",
            icon: "",
            disb: false,
          },
        ],
      },
    ],
    style: inshst.sal,
  },
  {
    title: "purchases-title",
    leftitle: "purch-system",
    of: of.parush,
    tabs: [
      { name: "صفـحـــــــــة ", nmbr: 1, icon: "", disb: false },
      // {name:'صفـحـــــــــة ',nmbr:2, icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          {
            name: "فاتورة مشتريات",
            nmbr: "(1)",
            path: "Invoces",
            icon: "",
            disb: pScr.purch,
            insh: inshs.Purches.purch,
          },
          {
            name: "مرتجع مشتريات",
            nmbr: "(2)",
            path: "Invoces",
            icon: "",
            disb: pScr.purchRet,
            insh: inshs.Purches.purchRet,
          },
          {
            name: "تقرير النشاط التجاري(مشتريات)",
            nmbr: "(3)",
            path: "Activityp",
            icon: "",
            disb: pScr.activ,
          },
          {
            name: "التقرير الضريبي للمشتريات",
            nmbr: "(4)",
            path: "sale-report-tax",
            icon: "",
            disb: pScr.activ,
            insh: inshs.Sales.purchVat,
          },
          {
            name: "purch-employee",
            nmbr: "(7)",
            path: "ReportEmpPurches",
            icon: "",
            disb: pScr.activ,
          },
          {
            name: "purch-analysis-rpt",
            nmbr: "(10)",
            path: "ReportPurchAnalysis",
            icon: "",
            disb: pScr.saleAnalysis,
          },
          // {name:'مشتريات مندوب خلال فتره', nmbr:'(4)',  path:'', icon:'',disb:false},
          // {name:'الإجمالي بحسب نوع الفاتورة', nmbr:'(5)',  path:'', icon:'',disb:false},
          // {name:'تقرير تفصيلي للمشتريات', nmbr:'(6)',  path:'', icon:'',disb:false},
        ],
      },
      {
        tab: [
          {
            name: "مشتريات موظف",
            nmbr: "(7)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "التقرير الضريبي للمشتريات",
            nmbr: "(8)",
            path: "",
            icon: "",
            disb: false,
          },

          {
            name: "تقرير تحليل ",
            nmbr: "(9)",
            path: "",
            icon: "",
            disb: false,
          },
        ],
      },
    ],
    style: inshst.purch,
  },
  {
    title: "stores-title",
    leftitle: "safe-system",
    of: of.safe,
    tabs: [
      { name: "صفـحـــــــــة ", nmbr: 1, icon: "", disb: false },
      { name: "صفـحـــــــــة ", nmbr: 2, icon: "", disb: false },
      // {name:'صفـحـــــــــة ',nmbr:3, icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          {
            name: "store-first-term",
            nmbr: "(1)",
            path: "first-term-invoice",
            icon: "",
            disb: pScr.safe,
            insh: inshs.Safes.safe,
          },
          {
            name: "str-inv-stat",
            nmbr: "(2)",
            path: "ReportSafesJard",
            icon: "",
            disb: pScr.safJrd,
          },
          {
            name: "str-inv-stat-unit",
            nmbr: "(3)",
            path: "SafeGrdUnits",
            icon: "",
            disb: pScr.safJrd,
          },
          {
            name: "str-adjustment",
            nmbr: "(4)",
            path: "reStore",
            icon: "",
            disb: pScr.safJrd,
          },
          // {name:'ارصدة المخزن حتى تاريخ', nmbr:'(4)',  path:'', icon:'',disb:false,},
          {
            name: "str-item-trans",
            nmbr: "(5)",
            path: "curr-details-rpt",
            icon: "",
            disb: pScr.safJrd,
          },
          {
            name: "str-turnover",
            nmbr: "(6)",
            path: "trun-items-avg",
            icon: "",
            disb: pScr.safJrd,
          },

          // {name:'اجمالي الحركات لصنف', nmbr:'(6)',  path:'', icon:'',disb:false,},
        ],
      },
      {
        tab: [
          // {name:'تسوية المخزون', nmbr:'(9)',  path:'', icon:'',disb:false,},
          {
            name: "str-transfer",
            nmbr: "(7)",
            path: "StoreTransfer",
            icon: "",
            disb: true,
          },
          {
            name: "str-receipt",
            nmbr: "(11)",
            path: "ReceiveST",
            icon: "",
            disb: true,
          },
          {
            name: "str-twreed",
            nmbr: "(7)",
            path: "store-supp",
            icon: "",
            disb: true,
          },
          {
            name: "str-srf",
            nmbr: "(8)",
            path: "store-exch",
            icon: "",
            disb: true,
          },
          // {name:'معدل الدوران والركود للأصناف', nmbr:'(12)',  path:'', icon:'',disb:false,},
        ],
      },
      // {
      //     tab:
      //     [
      //         {name:'الأصناف التي ستنتهي صلاحيتها', nmbr:'(13)',  path:'', icon:'',disb:false,},
      //     ],
      // },
    ],
    style: inshst.safe,
  },
  {
    title: "customers-and-suppliers-title",
    leftitle: "customers-and-suppliers-title",
    of: of.customer,
    tabs: [
      { name: "صفـحـــــــــة ", nmbr: 1, icon: "", disb: false },
      { name: "صفـحـــــــــة ", nmbr: 2, icon: "", disb: false },
    ],
    grid: [
      {
        tab: [
          {
            name: "cust-add",
            nmbr: "(1)",
            path: "Customers",
            icon: "",
            disb: pScr.addCust,
            insh: inshs.Customers[0],
          },
          {
            name: "sup-add",
            nmbr: "(2)",
            path: "Customers",
            icon: "",
            disb: pScr.addCust,
            // {name:'إضـافة مـورد جـديد', nmbr:'(2)',  path:'Customers', icon:'',disb:pScr.addCust,
            insh: inshs.Customers[1],
          },
          {
            name: "basic-customer-data",
            nmbr: "(3)",
            path: "SimData",
            icon: "",
            disb: pScr.groupCust,
            insh: inshs.bascData.Acts,
          },
          {
            name: "cust-account",
            nmbr: "(4)",
            path: "HesabCustA",
            icon: "",
            disb: pScr.custAcc,
            insh: inshs.Customers[2],
          },
          {
            name: "sup-account",
            nmbr: "(5)",
            path: "HesabCustM",
            icon: "",
            disb: pScr.custAcc,
            insh: inshs.Customers[3],
          },
          {
            name: "cust-balance",
            nmbr: "(6)",
            path: "BalanceCustRPT",
            icon: "",
            disb: pScr?.custBalance,
            insh: inshs.Customers[4],
          },
        ],
      },
      {
        tab: [
          // {name:'ارصدة حسابات الموردين', nmbr:'(7)',  path:'BalanceCustRPT', icon:'',disb:true,
          // insh:inshs.Customers[5] },
          {
            name: "cust-last-pay",
            nmbr: "(8)",
            path: "pay-trans",
            icon: "",
            disb: pScr.custPayTrans,
          },
          // {name:'حركة اخر سداد الموردين', nmbr:'(9)',  path:'pay-trans', icon:'',disb:false,},
          // {name:'اجمالي الفواتير بحسب العملاء', nmbr:'(10)',  path:'', icon:'',disb:false,},
          // {name:'اجمالي الفواتير بحسب الموردين', nmbr:'(11)',  path:'', icon:'',disb:false,},
          // {name:'اجمالي المبيعات والمشتريات العملاء / الموردين', nmbr:'(12)',  path:'', icon:'',disb:false,},
        ],
      },
    ],
    style: inshst.custm,
  },
  {
    title: "accounts-title",
    leftitle: "account-system",
    of: of.account,
    tabs: [
      { name: "صفـحـــــــــة ", nmbr: 1, icon: "", disb: false },
      { name: "صفـحـــــــــة ", nmbr: 2, icon: "", disb: false },
      { name: "صفـحـــــــــة ", nmbr: 3, icon: "", disb: false },
      // {name:'صفـحـــــــــة ',nmbr:4, icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          {
            name: "accounts-tree",
            nmbr: "(1)",
            path: "accounts-index",
            icon: "",
            disb: pScr.accountTree,
          },
          {
            name: "account-report",
            nmbr: "(2)",
            path: "ReportAcc",
            icon: "",
            disb: pScr.acc,
          },
          {
            name: "ext-receipt",
            nmbr: "(3)",
            path: "ext-receipt",
            icon: "",
            disb: pScr.sandQKh,
            insh: inshs.Accounts.sQKharji,
          },
          {
            name: "ext-exchange",
            nmbr: "(4)",
            path: "ext-exchange",
            icon: "",
            disb: pScr.sandSKh,
            insh: inshs.Accounts.sSKharji,
          },
          {
            name: "int-receipt",
            nmbr: "(5)",
            path: "int-receipt",
            icon: "",
            disb: pScr.sandQD,
            insh: inshs.Accounts.internalSQ,
          },
          {
            name: "int-exchange",
            nmbr: "(6)",
            path: "int-exchange",
            icon: "",
            disb: pScr.sandSD,
            insh: inshs.Accounts.internalSS,
          },
          // {name:'سند صرف داخلي مفرد', nmbr:'(4)',  path:'', icon:'',disb:false,},
        ],
      },
      {
        tab: [
          // {name:'شجرة الحسابات', nmbr:'(1)',  path:'accounts-index', icon:'',disb:true,},

          // {name:'كشف حساب عام', nmbr:'(1)',  path:'ReportAcc', icon:'',disb:pScr.acc,},
          {
            name: "mult-receipt",
            nmbr: "(7)",
            path: "mult-receipt",
            icon: "",
            disb: pScr.multiQ,
          },
          {
            name: "mult-exchange",
            nmbr: "(8)",
            path: "mult-exchange",
            icon: "",
            disb: pScr.multiS,
          },
          {
            name: "القيود الأفتتاحية",
            nmbr: "(14)",
            path: "opening-rest",
            icon: "",
            disb: true,
          },

          // {name:'عرض السندات الداخليه', nmbr:'(9)',  path:'', icon:'',disb:false,},
          // {name:'عرض السندات الخارجيه', nmbr:'(10)',  path:'', icon:'',disb:false,},
          // {name:'كشف حساب عام', nmbr:'(9)',  path:'ReportAcc', icon:'',disb:true,},
          // {name:'كشف حساب 2', nmbr:'(11)',  path:'', icon:'',disb:false,},
          {
            name: "daily-rest",
            nmbr: "(11)",
            path: "restInsert",
            icon: "",
            disb: pScr.restInsert,
          },
          {
            name: "tax-dec",
            nmbr: "(12)",
            path: "reportTax",
            icon: "",
            disb: pScr.taxRPT,
          },
          {
            name: "acc-sys-rpt",
            nmbr: "",
            path: "MainRBalance",
            icon: "",
            disb: pScr.accountRPT,
          },
        ],
      },
      {
        tab: [
          // {name:'القيود الأفتتاحية', nmbr:'(14)',  path:'', icon:'',disb:false,},
          // {name:'ادخال القيود اليومية', nmbr:'(15)',  path:'', icon:'',disb:false,},
          // {name:'عرض القيود اليومية', nmbr:'(16)',  path:'', icon:'',disb:false,},
          // {name:'اليومية العامة', nmbr:'(17)',  path:'', icon:'',disb:false,},
          // {name:'كشف حساب متقدم', nmbr:'(18)',  path:'', icon:'',disb:false,},
          // {name:'تقارير نظام الحسابات', nmbr:'',  path:'MainRBalance', icon:'',disb:pScr.accountRPT,},
          {
            name: "تقرير تحليل مراكز التكلفة",
            nmbr: "",
            path: "costRpt",
            icon: "",
            disb: pScr.accountRPT,
          },
        ],
      },
      {
        tab: [
          {
            name: "ميزان المراجعة رئيسي",
            nmbr: "(18)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "ميزان المراجعة تحليلي",
            nmbr: "(19)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "ارباح وخسائر رئيسي",
            nmbr: "(20)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "ارباح وخسائر تحليلي",
            nmbr: "(21)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "ميزانية عمومية رئيسي",
            nmbr: "(22)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "ميزانية عمومية تحليلي",
            nmbr: "(23)",
            path: "",
            icon: "",
            disb: false,
          },
        ],
      },
    ],
    style: inshst.account,
  },
  {
    title: "input-basic-data",
    leftitle: "basic-information-title",
    of: of.basic,
    tabs: [
      { id: 1, name: "page ", nmbr: 1, icon: "", disb: false },
      { id: 2, name: "page ", nmbr: 2, icon: "", disb: false },
      { id: 3, name: "page ", nmbr: 3, icon: "", disb: false },
      { id: 4, name: "page ", nmbr: 4, icon: "", disb: false },
    ],
    grid: [
      {
        tab: [
          {
            name: "basic-found-data",
            nmbr: "(1)",
            path: "Foundation",
            icon: "",
            disb: pScr.found,
            insh: inshs.bascData.Foundation,
          },
          {
            name: "basic-unit-data",
            nmbr: "(2)",
            path: "SimData",
            icon: "",
            disb: pScr.unit,
            insh: inshs.bascData.Units,
          },
          {
            name: "basic-product-data",
            nmbr: "(3)",
            path: "item",
            icon: "",
            disb: pScr.item,
            insh: itmes,
          },
          {
            name: "basic-group-product",
            nmbr: "(4)",
            path: "groups",
            icon: "",
            disb: pScr.itemGroup,
            insh: inshs.bascData.groups,
          },
          // {name:'مجموعات الأصناف', nmbr:'(5)',  path:'SimData', icon:'',disb:pScr.itemGroup,
          // insh:inshs.bascData.groups},
          {
            name: "basic-group-tax",
            nmbr: "(5)",
            path: "SimData",
            icon: "",
            disb: pScr.taxGroup,
            insh: inshs.bascData.tax_groups,
          },
          {
            name: "basic-branch-data",
            nmbr: "(6)",
            path: "NormData",
            icon: "",
            disb: pScr.branch,
            insh: inshs.bascData.Branches,
          },
          // {name:'تعريف الوحدات', nmbr:'(3)',  path:'SimData', icon:'',disb:pScr.unit,
          //  insh:inshs.bascData.Units},
          // {name:'تعريف الأصناف', nmbr:'(4)', path:'item', icon:'',disb:pScr.item,
          //  insh:inshs.bascData.itmes},

          // {name:'المجموعات الضريبية', nmbr:'(6)',  path:'SimData', icon:'',disb:pScr.taxGroup,
          //  insh:inshs.bascData.tax_groups},
        ],
      },
      {
        tab: [
          {
            name: "basic-safe-data",
            nmbr: "(7)",
            path: "safes",
            icon: "",
            disb: pScr.safes,
            insh: inshs.bascData.Safes,
          },
          {
            name: "basic-stock-data",
            nmbr: "(8)",
            path: "stocks",
            icon: "",
            disb: pScr.stocks,
            insh: inshs.bascData.Stocks,
          },
          {
            name: "basic-bank-data",
            nmbr: "(7)",
            path: "banks",
            icon: "",
            disb: pScr.bank,
            insh: inshs.bascData.Banks,
          },
          {
            name: "basic-center-data",
            nmbr: "(8)",
            path: "costCenters",
            icon: "",
            disb: pScr.centr,
            insh: inshs.bascData.costcenter,
          },
          {
            name: "basic-omla-data",
            nmbr: "(9)",
            path: "Omlat",
            icon: "",
            disb: pScr.omla,
            insh: inshs.bascData.omlat,
          },
          {
            name: "basic-books-data",
            nmbr: "(10)",
            path: "SimData",
            icon: "",
            disb: pScr.accBook,
            insh: inshs.bascData.AccountBooks,
          },
        ],
      },
      {
        tab: [
          {
            name: "basic-customer-data",
            nmbr: "(11)",
            path: "SimData",
            icon: "",
            disb: pScr.custGroup,
            insh: inshs.bascData.Acts,
          },
          // {name:'تعريف المخرجين', nmbr:'(13)',  path:'', icon:'',disb:pScr.mokharg},
          {
            name: "basic-countery-data",
            nmbr: "(14)",
            path: "SimData",
            icon: "",
            disb: pScr.country,
            insh: inshs.bascData.Countries,
          },
          {
            name: "basic-cities-data",
            nmbr: "(15)",
            path: "SimData",
            icon: "",
            disb: pScr.city,
            insh: inshs.bascData.Cities,
          },
          {
            name: "basic-area-data",
            nmbr: "(16)",
            path: "SimData",
            icon: "",
            disb: pScr.area,
            insh: inshs.bascData.Areas,
          },
          {
            name: "basic-div-data",
            nmbr: "(17)",
            path: "SimData",
            icon: "",
            disb: pScr.mang,
            insh: inshs.bascData.Managements,
          },
          {
            name: "basic-depart-data",
            nmbr: "(18)",
            path: "SimData",
            icon: "",
            disb: pScr.dept,
            insh: inshs.bascData.Departments,
          },
        ],
      },
      {
        tab: [
          {
            name: "basic-jobs-data",
            nmbr: "(19)",
            path: "SimData",
            icon: "",
            disb: pScr.job,
            insh: inshs.bascData.jobs,
          },
          {
            name: "bsic-card-pay",
            nmbr: "(20)",
            path: "SimData",
            icon: "",
            disb: pScr.cardType,
            insh: inshs.bascData.CardTypes,
          },
          {
            name: "تعريف المندوبين",
            nmbr: "(12)",
            path: "salesPerson",
            icon: "",
            disb: pScr.salesmen,
          },
        ],
      },
    ],
    style: inshst.bascData.all,
  },
  {
    title: "users-and-permissions-title",
    leftitle: "users-and-permissions-title",
    of: of.users,
    tabs: [
      // {name:'صفـحـــــــــة ',nmbr:1, icon:'',disb:false},
      // {name:'صفـحـــــــــة ',nmbr:2, icon:'',disb:false},
      // {name:'صفـحـــــــــة ',nmbr:3, icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          {
            name: "تعريف الموظف",
            nmbr: "(1)",
            path: "employees",
            icon: "",
            disb: pScr.found,
          },
          {
            name: "؟اضافة مستخدمين",
            nmbr: "(2)",
            path: "users-curd",
            icon: "",
            disb: pScr.found,
          },
          {
            name: "صلاحيات المستخدمين",
            nmbr: "(3)",
            path: "user-perms",
            icon: "",
            disb: true,
          },
          {
            name: "تغيير كلمة المرور",
            nmbr: "(4)",
            path: "change-password",
            icon: "",
            disb: true,
          },
          // {name:'خصائص المستخدم', nmbr:'(5)',  path:'', icon:'',disb:false,},
          // {name:'النسخ الاحتياطي واستعادة البيانات', nmbr:'(6)',  path:'', icon:'',disb:false,},
        ],
      },
    ],
    style: inshst.user,
  },
  {
    title: "system-settings-title",
    leftitle: "system-settings-title",
    of: of.setting,
    tabs: [
      { name: "page ", nmbr: 1, icon: "", disb: false },
      // {name:'صفـحـــــــــة  ',nmbr:2, icon:'',disb:false},
    ],
    grid: [
      {
        tab: [
          // {name:'إعدادت الطابعات', nmbr:'(1)',  path:'Invoces', icon:'',disb:false,},
          {
            name: "الإعدادات الإفتراضية",
            nmbr: "(2)",
            path: "default_setting",
            icon: "",
            disb: true,
          },
          {
            name: "تطبيق التحديثات على النظام",
            nmbr: "(1)",
            path: "Refresh",
            icon: "",
            disb: true,
          },
          {
            name: "استيراد وتصدير البيانات",
            nmbr: "(4)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "إعدادات عامة",
            nmbr: "(5)",
            path: "permissions",
            icon: "",
            disb: true,
          },
          {
            name: "إعداد الميزان الالكتروني",
            nmbr: "(10)",
            path: "electronic-scale",
            icon: "",
            disb: true,
          },
          {
            name: "Zatca Settings",
            nmbr: "(10)",
            path: "zatca-settings",
            icon: "",
            disb: true,
          },
          // {name:'إعدادات المشتريات', nmbr:'(6)',  path:'', icon:'',disb:false,},
        ],
      },
      {
        tab: [
          {
            name: "إعدادات المخازن",
            nmbr: "(7)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "إعدادات البيانات الاساسية",
            nmbr: "(8)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "إعدادات اخرى",
            nmbr: "(9)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "إعداد الميزان الالكتروني",
            nmbr: "(10)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "اعداد التقارير على الايميل",
            nmbr: "(11)",
            path: "",
            icon: "",
            disb: false,
          },
          {
            name: "إقفال السنه",
            nmbr: "(12)",
            path: "",
            icon: "",
            disb: false,
          },
        ],
      },
    ],
    style: inshst.setting,
  },
];

const transToSal = inshs.Sales.sal;
export { local, elIcon, itmes, autos, inshs, transToSal };
