import React, { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";

import LoadingPage from "../../../../services/LoadingPage";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";
import HeaderA from "./HeaderA";
import BodyA, { Totals } from "./BodyA";
import FooterAC from "./FooterAC";
import { Grid, Typography } from "@material-ui/core";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { Rept } from "../../../../Context/InshData";
import useChunkMyData from "../../../../components/Hooks/useChunkMyData";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
function OnePage(props) {
  const { found, chunk, isFirst, isLast, num ,info} = props;
  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>

      <Fragment>
      <Grid xs={12} item  style={{ maxHeight:"25.8cm" ,minHeight:"25.8cm"}}>
        {isFirst && <HeaderA info={info} />}
        <BodyA
          data={chunk ?? []}
          isFullPage={!isFirst ? true : false}
          isLast={isLast}
        />
        </Grid>
        <Grid xs={12} item  style={{ maxHeight:"3.6cm" ,minHeight:"3.6cm"}}>
          <FooterAC found={found}
            isLast={isLast}
            num={num} />
        </Grid>
      </Fragment>
    </Grid>
  );
}

function ReviewAccountContainer(props) {
  // const [totalHeight, setTotalHeight] = useState(0);
  // const dataRef =useRef(null);
  const { chunks, chunksCount } = useChunkMyData(Rept.data, 22, 35);

  // const [chunks, setChunks] = useState(_.chunk(Rept.data, 14));
  // const firstChunk = _.chunk(Rept.data, 12);
  const [info, setInfo] = useState({});
  const url = uri + `Foundation?branch=${sto.branchi}`;

  const { data: found, fetching } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });

  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    getInfo();
  }, []);

  if (fetching || !found || !chunks) return <div></div>;


  return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>
      <Typography component="div" >

        {chunks.map((page, i) => {
          return (
            <OnePage
              {...{
                found,
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunksCount - 1,
                num: i + 1,
                info,
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default ReviewAccountContainer;
