import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import SafeGrd from "./SafeGrd";
import LoadingPage from "../../../../services/LoadingPage";
const defaultAutos = {
  Safes: [],
};

export default function SafeGrdCon() {
  
  
  const tb = {
    wait: true,
    Safes: true,
    groups: true,
    stor: sto,
    parms: { safe: -1},
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb
  );

  if (fetchAll || !AutoOptions?.Safes) return <LoadingPage />;

  return (
    <SafeGrd
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
      }}
    />
  );
}
