import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import clsx from "clsx";
import { uri } from "../../../../help/Api";
import { insh, sto, wsto } from "../../../../Context/InshData";
import { autos } from "../../../../Context/Local";
import moment from "moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import { useHistory, useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// import tableIcons from "../../../../Elemnts/TableIcons";
import { TableIcons as tableIcons } from "../../../../Elemnts/TableIcons";
import Container from "@material-ui/core/Container";
import useBreakpoint from "../../../../utils/useBreakpoint";
import {
  useStylesContainers,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesLbl,
  useStylesCAB,
  useStylesDPM,
} from "../../../../St/comps/UseStyle";

import { stElemnt } from "../../../../St/comps/cStyle";
//=====================================================================
import SubUnits from "../subUnits/SubUnits";

import ItemsImage from "./items-image";
import BarcodeDialog from "./barcodePrint/BarcodeDialog";
import ItemsList from "./items-list";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import KhamItems from "./KhamItems/items_kham";

// let prm = {Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl}
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function Items({ locat, isDialog, DialogClose }) {
  // const locat = useLocation();
  const point = useBreakpoint();

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasCAB = useStylesCAB();
  const clasPM = useStylesDPM();

  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const [showPic, setShowPic] = useState(false);

  const inshParm = { itake: 20, symple: "", groupSearch: null };
  const [parm, setParm] = useState(inshParm);

  const timer = React.useRef();
  const [uiElement, setUiElement] = useState({});

  const insOrders = [
    { id: 1, name: "ترتيب حسب التاريخ" },
    { id: 2, name: "ترتيب حسب رقم المستند" },
  ];

  const [transition, setTransition] = React.useState(undefined);

  const insReviws = [
    { id: 1, name: "إجمالي" },
    { id: 2, name: "تفصيلي" },
  ];
  const [currUnitList, setCurrUnitList] = useState([]);

  const [currKhamList, setCurrKhamList] = useState([]);

  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });

  const [style, setStyle] = useState({});

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const [prevent, setPrevent] = useState(insh.prevents);

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
  const handCloseDw = async () => {
    newToI(1);
    await setOpenPMD(false);
  };
  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };
  /* e PMD */

  const [items, setItems] = useState(insh.items);
  const [gets, setGets] = useState(insh.tbname);

  const [kham, setKham] = useState(insh.khams);
  const [currenciesAll, setCurrenciesAll] = useState([]);
  const inshValueAuto = {
    group_id: {},
    groupSearch: {},
    branch: {},
    tax_group: {},
    unit: {},
    type: {},
  };
  const [valueAutos, setValueAutos] = useState(inshValueAuto);

  const setDefultAutos = (re, pGets, def_taxGroup) => {
    setValueAutos({
      group_id: pGets.groups[0],
      // groupSearch:pGets.groups[0],
      branch: pGets.Branches[1],
      tax_group: def_taxGroup,
      unit: pGets.Units[0],
      type: autos.currType[0],
    });
  };

  // const ust_o = (updatedData) => {
  function usto(olddata) {}

  const reloadGets = async (prop) => {
    const tb = {
      wait: true,
      other: true,
      Currencies: true,

      parms: {
        scurrenciestype: "C",
        tname: "curr",
        iempid: sto.empi,
        takei: parm.itake,
        sSearch: parm.symple,
        group_id: parm.groupSearch,
      },
      autos: {},
    };

    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setGets({
          ...gets,
          Currencies: res.data.Currencies,
          other: res.data.other,
        });
        setCurrenciesAll(res.data.Currencies);
        const defaultTG = gets.tax_groups?.filter((t) => t.isDefault);
        setItems({
          ...insh.items,
          id: res.data.other.lastid,
          barcode: res.data.other.barcode,
          group_id: gets.groups[0].id,
          tax_group: defaultTG[0].id,
          tax: defaultTG[0].i,
          type: autos.currType[0].id,
          unit: gets.Units[0].id,
        });

        ButtonSuccess(prop, 1000);
      })
      .catch((error) => {
        console.log("the error is", error);
        alert("خطاء في اعادة جلب");
        ButtonSuccess(prop, 1000);
      });
  };

  const loadGets = async (ptb) => {
    const tb = {
      wait: true,
      other: true,
      groups: true,
      Branches: true,
      tax_groups: true,
      Units: true,
      Currencies: true,
      parms: {
        iempid: sto.empi,
        fltrBranch: true,
        scurrenciestype: "C",
        tname: "curr",
        takei: 20,
      },
      autos: { Branches: true, Units: true, allGroups: true, tax_groups: true },
    };

    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setGets(res.data);
        const defaultTG = res.data.tax_groups?.filter(
          (t) => t.isDefault == true
        );
        setCurrenciesAll(res.data.Currencies);
        setItems({
          ...items,
          id: res.data.other.lastid,
          barcode: res.data.other.barcode,
          group_id: res.data.groups[0].id,
          tax_group: defaultTG[0].id,
          tax: defaultTG[0].i,
          unit: res.data.Units[0].id,
          type: autos.currType[0].id,
          subUnits: res.data.Units,
        });
        setDefultAutos(false, res.data, defaultTG[0]);
      })
      .catch((error) => {
        /*console.log('no Items');*/
      });
  };

  useEffect(() => {
    setUiElement(insh.UiElement);
    setSetting(locat.state.setting);
    setStyle(locat.state.style);
    loadGets(locat.state.setting);
  }, []);

  const changAutos = (prop, newValue) => {
    // console.log(prop+' - '+newValue.id)
    setValueAutos({ ...valueAutos, [prop]: newValue });
    setItems({
      ...items,
      [prop]: newValue.id,
      tax: prop == "tax_group" ? newValue.i : items.tax,
    });
  };
  const changeShowPicture = (newValue) => {
    // console.log(prop+' - '+newValue.id)
   if (newValue==false){
  

   }else{
    setShowPic(false);
   }
  };

  const changTField = (prop) => (e) => {
    // console.log(prop+' - '+e.target.value)
    setItems({ ...items, [prop]: e.target.value });
  };

  const changTFSearch = (prop) => (e) => {
    // console.log(prop+' - '+e.target.value)
    setParm({ ...parm, [prop]: e.target.value, itake: 5 });
  };

  const chekeBreack = () =>
    point == "1" || point == "2" || point == "3"
      ? handOpenDw(TransitionUp)
      : null;

  const actionItem = (item) => {
    console.log("the item is", item);
    chekeBreack();
    let res = { group_id: {}, branch: {}, tax_group: {}, unit: {}, type: {} };
    gets.groups.map((x) => {
      if (x.id == item.group_id) res.group_id = x;
    });
    gets.Branches.map((x) => {
      if (x.id == item.branch) res.branch = x;
    });
    gets.tax_groups.map((x) => {
      if (x.id == item.tax_group) res.tax_group = x;
    });
    gets.Units.map((x) => {
      if (x.id == item.unit) res.unit = x;
    });
    autos.currType.map((x) => {
      if (x.id == item.type) res.type = x;
    });
    setItems({
      ...items,
      id: item.id,
      barcode: item.barcode,
      nameEN: item.nameEN,
      symbol: item.symbol,
      purch_price: item.purch_price,
      sale_price: item.sale_price,
      min_sale_price: item.min_sale_price,
      Distributor_price: item.Distributor_price,
      jomla_price: item.jomla_price,
      tax: item.tax,
      img: item.img,
      type: item.type,
      group_id: item.group_id,
      unit: item.unit,
      prody_date: moment(item.prody_date).format("YYYY-MM-DD"),
      expiry_date: moment(item.expiry_date).format("YYYY-MM-DD"),
      is_tab3_curr: item?.is_tab3_curr,
    });

    // eds-changes
    setCurrUnitList(
      item.subUnits.map((su) => {
        var unitName = gets.Units.find((x) => x.id === su.unit);
        return { ...su, unitName: unitName ? unitName.name : "" };
      })
    );

   

   


    setValueAutos({
      ...valueAutos,
      group_id: res.group_id,
      branch: res.branch,
      tax_group: res.tax_group,
      unit: res.unit,
      type: res.type,
    });

    setPrevent(false);

    //  setDefultAutos(true,res);
    setUiElement({ ...uiElement, DeleIcon: false });
    timer.current = window.setTimeout(
      () => setUiElement({ ...uiElement, DeleIcon: true }),
      300
    );
  };

  const handNewItem = () => {
    const defaultTG = gets.tax_groups?.filter((t) => t.isDefault == true);
    setDefultAutos(true, gets, defaultTG[0]);
    console.log(defaultTG);

    setItems({
      ...insh.items,
      id: gets.other.lastid,
      barcode: gets.other.barcode,
      unit: gets.Units[0].id,
      group_id: gets.groups[0].id,
      type: autos.currType[0].id,
      tax_group: defaultTG[0].id,
      tax: defaultTG[0].i,
    });
    setParm(inshParm);
    setUiElement({ ...uiElement, DeleIcon: false });
    setCurrUnitList([]);
  };

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });

  const ButtonWitting = (prop) => {
    reloadGets(prop);
  };

  const ButtonSuccess = (prop, timr) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (prop != "save") handNewItem();
      setPrevent(true);
    }, timr);
  };

  const handLoads = async (value, stosn) => {
    const nstos = stosn;
    Object.keys(value).forEach((element) => {
      nstos[element] = value[element];
    });
    window.localStorage.setItem("sto", JSON.stringify(nstos));
  };

  const storgeing = async () => {
    await axios
      .post(uri + "Res", {
        wait: true,
        Currencies: true,
        View_CurrencieUnit: true,
        View_CurrenciesToInv: true,
        parms: {
          scurrenciestype: "I",
          scurrencieShow: "Fav",
          currType: 2, // out
          currShow: "M",
          iempid: sto.empi,
          igroupid: -1,
          takei: 50,
          sSearch: "",
        },
        autos: {},
      })
      .then((res) => {
        handLoads(res.data, wsto);
      })
      .catch((error) => {
        //  console.log('nooooo sttorgeing');
        alert("لم يتم التحديث");
      });

    //  }, 3000);
  };

  const newToI = (timr) => {
    ButtonLoading("new");
    ButtonSuccess("new", timr);
  };

  const will = () => {
    // alert(parseFloat(items.sale_price) +' <= '+ parseFloat(items.purch_price))
    let ob = { msg: "قم بإضافة ", txt: "" };
    if (items.barcode == "") ob.txt = t("forms:barcode");
    else if (items.symbol == "") ob.txt = t("forms:product-name-ar");
    else if (items.purch_price === "") ob.txt = t("forms:purch-price");
    else if (items.sale_price == "") ob.txt = t("forms:sale-price");
    else if (parseFloat(items.sale_price) < parseFloat(items.purch_price)) {
      ob.txt = "سعر البيع اكبر او يساوي سعر الشراء";
      ob.msg = "يجب أن يكون ";
    }
    ob.msg += ob.txt;
    return ob;
  };

  const addToBS = () => {
    console.log("thre group is ", items);
    let action = will();
    if (action.txt == "") {
      ButtonLoading("save");
      const currData = {
        itemData: items,
        subUnits: currUnitList,
      };
      console.log("the curre data is ", currData);

      prevent
        ? axios
            .post(uri + "Currencies", currData)
            .then((res) => {
              if (res.data) {
                alert("رقم الباردكود موجود مسبقا");
                ButtonSuccess("save", 500);
              } else {
                usto(items);
                ButtonWitting("save");
                storgeing();
                setCurrUnitList([]);
              }
            })
            .catch((res) => {
              alert("تم إضافة الصنف هذا مسبقا");
              ButtonSuccess("save", 1000);
            })
        : // axios.put('http://localhost:8184/api/Currencies', items)
          axios
            .put(uri + "Currencies", currData)
            .then((res) => {
              ButtonWitting("save");
              setCurrUnitList([]);
            })
            .catch((res) => {
              alert("لم يتم  تعديل هذا الصنف");
              ButtonSuccess("save", 1000);
            });
    } else {
      alert(action.msg);
    }
  };

  const hRowDelete = (id) => {
    let reResData = [...currenciesAll];
    reResData = reResData.filter((t) => t.id !== id);
    setCurrenciesAll(reResData);
    ButtonSuccess("delete", 1000);
  };

  const deleteToI = async (e, id, screen) => {
    await e.preventDefault();
    ButtonLoading("delete");
    axios
      .delete(uri + `Currencies?id=${id}`)
      .then((res) => {
        res.data != -1
          ? hRowDelete(id)
          : alert("لا يمكن حذف هذا الصنف لانه مرتبط بعمليات اخرى");
        ButtonSuccess("delete", 500, true);
      })
      .catch((err) => {
        alert("لم يتم الحذف");
        ButtonSuccess("delete", 500);
      });
  };

  // ==== b sd
  const [open, setOpen] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  const backHome = () => {
    his.push({ pathname: "/" });
  };
  const back = () => {
    if (!isDialog) his.goBack();
    else DialogClose(false);
  };
  const { t } = useTranslation(["translation", "forms"]);
  let his = useHistory();
  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  const frontHeader = () => {
    return (
      <Grid item xs={12}>
        <Chip
          size="small"
          className={clashd.ChipHdSale}
          label={t("translation:basic-product-data")}
          color="primary"
        />
      </Grid>
    );
  };

  const FrontBtnAction = () => {
    return (
      <Fragment>
        {uiElement.DeleIcon === true ? (
          <div className={clasCAB.wrapperCAB}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={buttonClassnameDelete}
              startIcon={<DeleteIcon />}
              style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
              disabled={loading.delete}
              onClick={(e) => deleteToI(e, items.id, setting.screen)}
            >
              {t("forms:delete")}
            </Button>
            {loading.delete && (
              <CircularProgress
                size={24}
                className={clasCAB.buttonProgressCAB}
              />
            )}
          </div>
        ) : null}
        {uiElement.DeleIcon === true ? (
          <div className={clasCAB.wrapperCAB}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={buttonClassnameDelete}
              style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
              disabled={loading.delete}
              onClick={(e) => {
                setBarcodeDialogOpen(true);
              }}
            >
              {t("forms:barcode")}
            </Button>
          </div>
        ) : null}

        <div className={clasCAB.wrapperCAB}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
            className={buttonClassnameSave}
            disabled={loading.save}
            onClick={addToBS}
            startIcon={<SaveIcon />}
          >
            {" "}
            {t("forms:save")}{" "}
          </Button>
          {loading.save && (
            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
          )}
        </div>

        <div className={clasCAB.wrapperCAB}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
            className={buttonClassnameNew}
            disabled={loading.new}
            onClick={() => newToI(1000)}
            startIcon={<AddIcon />}
          >
            {" "}
            {t("forms:new")}{" "}
          </Button>
          {loading.new && (
            <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />
          )}
        </div>
      </Fragment>
    );
  };

  const frontLeftDown = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
        <Grid
          item
          container
          xs={12}
          className={[clsConts.subDown, clsConts.subDownL]}
        >
          <Grid item xs={12}>
            <ItemsList
              {...{
                items: currenciesAll,
                actionItem,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const frontRightUp = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootup]}>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          className={[clsConts.subUp, clsConts.subUpR]}
        >
          <Grid item lg={3} md={3} sm={4} xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="id"
              label={t("forms:product-id")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input, readOnly: true }}
              type="text"
              name="id"
              value={items.id}
              onChange={changTField("id")}
            />
          </Grid>

          <Grid item lg={9} md={9} sm={4} xs={8}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="barcode"
              label={t("forms:barcode")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="barcode"
              value={items.barcode}
              onChange={changTField("barcode")}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="symbol"
              label={t("forms:product-name-ar")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="symbol"
              value={items.symbol}
              onChange={changTField("symbol")}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="nameEN"
              label={t("forms:product-name-en")}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name="nameEN"
              value={items.nameEN}
              onChange={changTField("nameEN")}
            />
          </Grid>

          <Grid item container>
            <Grid item container spacing={1} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                className={clasTF.tool}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                style={{
                  padding: "0px",
                  color: "#81007f",
                  textAlign: "-webkit-center",
                }}
              >
                <IconButton
                  color="inherit"
                  style={{
                    padding: "10px",
                    color: "#81007f",
                    border: "solid 0.1px",
                    borderRadius: 50,
                  }}
                  aria-label="open drawer"
                  // onClick={handleOpenCust_omer}
                >
                  <AddIcon />
                </IconButton>
              </Grid>

              <Grid item className={clasTF.tool} lg={5} md={10} sm={10} xs={9}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.group_id}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("group_id", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.groups}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("forms:product-group")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>

              <Grid item className={clasTF.tool} lg={5} md={12} sm={12} xs={12}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.branch}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("branch", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.Branches}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("forms:branch")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container spacing={1} lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={4} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="purch_price"
                  label={t("forms:purch-price")}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="purch_price"
                  value={items.purch_price}
                  onChange={changTField("purch_price")}
                />
              </Grid>

              <Grid item lg={6} md={4} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="sale_price"
                  label={t("forms:sale-price")}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="sale_price"
                  value={items.sale_price}
                  onChange={changTField("sale_price")}
                />
              </Grid>

              <Grid item lg={6} md={4} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="min_sale_price"
                  label={t("forms:little-sale-price")}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="min_sale_price"
                  value={items.min_sale_price}
                  onChange={changTField("min_sale_price")}
                />
              </Grid>

              <Grid item lg={6} md={4} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="Distributor_price"
                  label={t("moz-price")}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="Distributor_price"
                  value={items.Distributor_price}
                  onChange={changTField("Distributor_price")}
                />
              </Grid>

              <Grid item lg={6} md={4} sm={4} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="jomla_price"
                  label={t("forms:wholesale-price")}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name="jomla_price"
                  value={items.jomla_price}
                  onChange={changTField("jomla_price")}
                />
              </Grid>
              <Grid item lg={6} md={4} sm={4} xs={6}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.type}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("type", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={autos.currType}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("forms:product-type")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container lg={12} md={12} sm={12} xs={6}></Grid>

            <Grid item container spacing={1} lg={12} md={12} sm={12} xs={12}>
              <Grid item className={clasTF.tool} lg={7} md={7} sm={6} xs={6}>
                <Autocomplete
                  classes={clasAo}
                  value={valueAutos.tax_group}
                  labelPlacement=""
                  onChange={(event, newValue) => {
                    changAutos("tax_group", newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.tax_groups}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("forms:tax-group")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clrSale }}
                    />
                  )}
                />
              </Grid>

              <Grid item className={clasTF.tool} lg={5} md={5} sm={6} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="tax"
                  label=" % "
                  InputProps={{ className: clasTD.input, readOnly: true }}
                  type="text"
                  name="tax"
                  value={items.tax}
                  /*onChange={changTField('tax')}*/
                />
              </Grid>

              <Grid item container>
                <Grid
                  item
                  container
                  spacing={1}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Grid item lg={7} md={7} sm={6} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={valueAutos.unit}
                      labelPlacement=""
                      onChange={(event, newValue) => {
                        changAutos("unit", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.Units}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      // style={{  }}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("forms:default-unit")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={5} md={5} sm={6} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="prody_date"
                      label={t("forms:procuction-date")}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="prody_date"
                      value={items.prody_date}
                      onChange={changTField("prody_date")}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="expiry_date"
                      label={t("forms:expiry-date")}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="expiry_date"
                      value={items.expiry_date}
                      onChange={changTField("expiry_date")}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            fullWidth
                            checked={items?.is_tab3_curr}
                            onChange={(e) => {
                              setItems({
                                ...items,
                                is_tab3_curr: e.target.checked ? 1 : 0,
                              });
                              console.log("the currl is", items);
                            }}
                            name="is_tab3_curr"
                          />
                        }
                        size="small"
                        color="primary"
                        fontSize="small"
                        label="ضريبة تبغ"
                      />
                    </FormGroup>
                  </Grid>
                  {/* <Grid item lg={3} md={3} sm={3} xs={3}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            fullWidth
                            checked={showPic}
                            onChange={(e) => {
                             changeShowPicture(e);
                            }}
                            name="pic"
                          />
                        }
                        size="small"
                        color="primary"
                        fontSize="small"
                        label="Add Picture"
                      />
                    </FormGroup>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container lg={12} md={12} className={clsConts.rootup}>
            <Grid
              item
              container
              style={{ textAlign: "center" }}
              xs={12}
              className={[clsConts.subUp, clsConts.subUpL]}
            >
              <ItemsImage
                {...{
                  items,
                  setItems,
                }}
              />
            </Grid>
            {/* <label>صورة الصنف</label> */}
          </Grid>

          <SubUnits
            {...{
              addSubUnitFunc: () => {},
              units: gets.Units,
              currUnitList,
              setCurrUnitList,
            }}
          />
          
        </Grid>
      </Grid>
    );
  };

  const frontRightDown = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
        <Grid
          item
          container
          xs={12}
          className={[clsConts.subDown, clsConts.subDownR]}
        >
          asdfasf
        </Grid>
      </Grid>
    );
  };

  {
    /* s PMD */
  }
  const frontDialogForm = () => {
    return (
      <div>
        <Dialog
          fullScreen
          className={clasPM.rootPM}
          open={openPMD}
          onClose={handCloseDw}
          aria-labelledby="form-dialog-title"
          TransitionComponent={transition}
          key={transition ? transition.name : ""}
        >
          <DialogTitle id="form-dialog-title" className={claslbl.clor}>
            ادخل البيانات
          </DialogTitle>
          <DialogContent
            className={[clasPM.dialogContentPM, clasPM.diaCPMBasicDa]}
          >
            {frontRightUp()}
          </DialogContent>
          <DialogActions className={clasPM.dirRPM}>
            <div className={clasCAB.wrapperCAB}>
              <Button
                variant="outlined"
                color=""
                size="large"
                style={{ ...stElemnt.all.iconBtn }}
                className={buttonClassnameNew}
                disabled={loading.new}
                onClick={handCloseDw}
                // startIcon={<CloseIcon />}
              >
                {" "}
                {t("forms:cancel")}{" "}
              </Button>
              {loading.new && (
                <CircularProgress
                  size={24}
                  className={clasCAB.buttonProgressCAB}
                />
              )}
            </div>

            <div className={clasBA.growBA} />

            <FrontBtnAction />
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  {
    /* e PMD */
  }

  const frontAppBar = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          // className={clasBA.appBarBASal}
          style={{ ...style.bgImg, ...stElemnt.all.appBarBA, direction: "ltr" }}
        >
          <Toolbar className={clasBA.ToolbarBA} variant="dense">
            <Hidden smUp={["sm"]}>
              {!isDialog && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={backHome}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => back()}
              >
                <ReplyIcon />
              </IconButton>
            </Hidden>

            <Hidden only={["xs"]}>
              {!isDialog && (
                <Button
                  style={{ color: "#fff" }}
                  onClick={backHome}
                  startIcon={<CloseIcon />}
                >
                  {t("forms:exit")}
                </Button>
              )}
              <Button
                style={{ color: "#fff" }}
                onClick={() => back()}
                startIcon={<ReplyIcon />}
              >
                {t("forms:back")}
              </Button>
              {/* <Button style={{color:'#fff'}} onClick={newToI} startIcon={<AddBoxIcon />}>جديد</Button> */}
            </Hidden>
            {/* <Button color="primary">Primary</Button> */}

            <Hidden mdUp={["md"]}>
              <Fab
                color="primary"
                aria-label="add"
                style={{ ...style.fabButtonBA, ...stElemnt.all.fabButtonBA }}
                onClick={() => handOpenDw(TransitionUp)}
              >
                <PostAddIcon />{" "}
              </Fab>
            </Hidden>

            <div className={clasBA.growBA} />

            <Hidden smUp={["sm"]}>
              {/* ico left  */}
              {uiElement.DeleIcon === true ? (
                <div className={clasCAB.wrapperCAB}>
                  <Fab
                    size="medium"
                    color="primary" /*disabled={permis.De}*/
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    className={buttonClassnameDelete}
                    onClick={(e) => deleteToI(e, items.id, setting.screen)}
                  >
                    {success.delete ? <CheckIcon /> : <DeleteIcon />}
                  </Fab>
                  {loading.delete && (
                    <CircularProgress
                      size={56}
                      className={clasCAB.fabProgressCAB}
                    />
                  )}
                </div>
              ) : null}
            </Hidden>

            <Hidden smDown={["sm"]}>
              <FrontBtnAction />
            </Hidden>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography component="div" style={styheader}>
          <Grid item container>
            {/* S front Header */} {frontHeader()} {/* E front Header */}
            {/* S front Dialog */}
            <Hidden mdUp={["md"]}> {frontDialogForm()} </Hidden>
            {/* E front Dialog */}
            {/* S front Right */}
            <Grid container lg={5} md={5}>
              {/* S front up R */}
              <Hidden smDown={["sm"]}> {frontRightUp()} </Hidden>
              {/* E front up R */}

              {/* S front down R */}
              {/* <Hidden smDown={['sm']}> {frontRightDown()} </Hidden> */}
              {/* E front down R */}
            </Grid>
            {/* E front Right */}
            {/* S front Left */}
            <Grid container lg={7} md={7}>
              {/* S front up L */}
              {/* {frontLeftUp()}  */}
              {/* E front up L */}
              {/* S front down L */} {frontLeftDown()} {/* E front down L */}
            </Grid>
            {/* E front Left */}
            {/* S front down R */}
            <Hidden xsUp={["xs"]}> {frontRightDown()} </Hidden>
            {/* E front down R */}
            <BarcodeDialog
              open={barcodeDialogOpen}
              setOpen={setBarcodeDialogOpen}
              data={items}
            />
          </Grid>
          {/* S front fixed */} {frontAppBar()} {/* E front fixes */}
        </Typography>
      </Container>
    </React.Fragment>
  );
}
